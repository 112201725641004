<template>
    <div class="jump-page">
        <img src="../../assets/common/jump.png" alt="">
        <div class="title">正在跳转至下载...</div>
        <div class="text">下载完成后将会自动弹出安装界面
            请耐心等待
            若没有弹出安装界面，可自行在下载管理中选择嗨皮直播安装。</div>
    </div>
</template>

<script>

export default {

  data() {
    return {
      url: ''
    }
  },
  created()  {
    this.url = this.$route.query.url;
    this.jump()
  },
  methods: {
  	async jump() {
      let that = this;
		  setTimeout(function () {
        window.location.href = that.url
		  }, 2000)
    }
  }
};
</script>

<style lang="scss" scoped>
.jump-page {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    width: 170px;
    height: 96px;
  }
  .title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin: 40px 0 18px 0;
  }
  .text {
    width: 203px;
    text-align: center;
    font-size: 12px;
    color: #999999;
  }
}

</style>
